window.env = {
  'GENERIC_BACKEND_RAILS_ACCOUNT':'https://backend-account-p-dvcptst3aa-uw.a.run.app',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_PROJECTS':'NIVAL',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_LOCALES_PROJECTS': 'en,es',  
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_VERSION_PROJECTS': 'https://backend-nival-api-p-dvcptst3aa-uw.a.run.app',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_FONTS_PROJECTS':'WorkSans',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_FONTS_BOLD_PROJECTS':'WorkSans',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_RECAPTCHA_SITE_KEY_PROJECTS': '6LeQxTwoAAAAAM6eOl3pkzdbNE2yLyfR9cRwdlUX',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_APPSIGNAL_MONITORING_KEY_PROJECTS': 'fa7fa430-8866-4469-87d1-161a302830d4',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_GOOGLE_ANALYTICS': 'G-BCNTRW25W3'
};
