import React from "react";
import { useLoadProject } from "../hooks";

const withCustomProject = (WrappedComponent) => {
  const WithScripts = (props) => {
    useLoadProject();

    return <WrappedComponent {...props} />;
  };

  return WithScripts;
};

export default withCustomProject;
