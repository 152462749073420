import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
// import i18n from "i18next";
import App from './App/App'
import HtmlService from "./Services/HtmlService";
import RoutingService from "./Services/RoutingService";

import { 
  projects_names, 
  projects_locales, 
  projects_versions, 
  projects_token_payload_urls,
  projects_google_recaptcha_site_keys,
  projects_app_signal_monitoring_keys,
  projects_fonts,
  projects_fonts_bold
} from "./parameters";

let allowed_locales = null;
let version = null;
let token_payload_url = null;
let google_recaptcha_site_key = null;
let app_signal_monitoring_key = null;
let font = null;
let font_bold = null;

let plattform = RoutingService.getParamValue('plattform', 'desktop');

if (plattform === 'desktop') {
  let hidden_on_mobile = document.getElementById('hidden-on-mobile');
  hidden_on_mobile.style.display = 'block';  
}

function getProjectData() {
  let projects = projects_names.split('|');
  let locales = projects_locales.split('||');
  let versions = projects_versions.split('||');
  
  let google_recaptcha_site_keys = null;
  if (projects_google_recaptcha_site_keys && projects_google_recaptcha_site_keys !== undefined) {
    google_recaptcha_site_keys = projects_google_recaptcha_site_keys.split('||');
  }

  let fonts = projects_fonts.split('||');
  let fonts_bold = projects_fonts_bold.split('||');

  let token_payload_urls = null;
  if (projects_token_payload_urls) token_payload_urls = projects_token_payload_urls.split('||');

  let app_signal_monitoring_keys = null;
  if (projects_app_signal_monitoring_keys) app_signal_monitoring_keys = projects_app_signal_monitoring_keys.split('||');

  let url_project = RoutingService.getParamValue('project') || projects[0];
  if (!url_project) return null;

  let index = projects.indexOf(url_project.toUpperCase());
  if (index === -1) return null;

  allowed_locales = locales[index];
  version = versions[index];

  if (google_recaptcha_site_keys && google_recaptcha_site_keys.length > 0) {
    google_recaptcha_site_key = google_recaptcha_site_keys[index];
  }

  font = fonts[index];
  font_bold = fonts_bold[index];

  if (token_payload_urls) token_payload_url = token_payload_urls[index];
  if (app_signal_monitoring_keys) app_signal_monitoring_key = app_signal_monitoring_keys[index];
}

getProjectData();
HtmlService.showLoadingComponent(true);

// if (window.self === window.top) {
//   import("./App/components/Error/ErrorScreen").then(({ default: Error404 }) => {
//     document.getElementById('loader').remove()
//     document.body.classList = []
//     ReactDOM.render(
//       <React.StrictMode>
//         <Error404 error="404" />
//       </React.StrictMode>,
//       document.getElementById("root")
//     );
//   });
// } else {

ReactDOM.render(
  <App 
    allowed_locales={allowed_locales} 
    version={version} 
    token_payload_url={token_payload_url}
    google_recaptcha_site_key={google_recaptcha_site_key}
    app_signal_monitoring_key={app_signal_monitoring_key}
    font={font}
    font_bold={font_bold}
  />, 
  document.getElementById('root')
);

// }


const updateHandler = (registration: ServiceWorkerRegistration) => {
  // if (window.confirm(i18n.t("Update available. Do you want to reload?"))) {
    setTimeout(() => {

      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });

      window.caches.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            return window.caches.delete(key);
          })
        );
      });

      console.log('Updating content...');

      setTimeout(() => {
        window.location.reload();
      }, 1000);

    }, 100);
  // }
};

serviceWorker.register({
  onUpdate: updateHandler,
});

// serviceWorker.unregister();
