let pt = {
     translation: {
          'Has already been taken': 'Já foi usado',
          'Range overflow': 'Acima do permitido',
          'Pattern mismatch': 'Padrão errado',
          'Range underflow': 'Abaixo do que é permitido',
          'Too long': 'Muito longo',
          'Type mismatch': 'Tipo de dados inválido',
          'Required': 'Obrigatório',

          'Username or email': 'Nome de usuário ou email',
          'Password or Access code': 'Senha ou código de acesso',
          'Remember': 'Lembrar',
          'Log In': 'Entrar',
          'English': 'Inglês',
          'Spanish': 'Espanhol',
          'Invalid credential': 'Credenciais inválidas',
          'Account': 'Conta',
          'Profile': 'Perfil',
          'Logout': 'Fechar Sessão',
          'Receive quick access password by email': 'Receba o código de acesso rápido por email',
          'Resend quick access password by email': 'Reenviar o código de acesso rápido por email',
          'Enter email': 'Especifique um email',
          'Send password': 'Enviar senha',
          'Unable to send email': 'O email não pôde ser enviado',
          'Verify the email account for getting your new quick access password': 'Verifique a conta de e-mail para obter seu novo código de acesso rápido',
          'Cancel': 'Cancelar',
          'You are already Login': 'Voce ja esta autenticado',
          'Loading account info': 'Carregando as informações da sua conta',
          'Your access code has expired': 'Seu código de acesso expirou',
          'Set a secure password': 'Defina uma senha forte',
          'Your access code is not secure enough': 'Seu código de acesso não é seguro',
          'Please, set a secure password': 'Por favor, defina uma senha forte',
          'Current Access code': 'Código de acesso atual',
          'New password': 'Nova Senha',
          'Confirm password': 'Confirmar senha',
          'Set': 'Configuração',
          'Password and confirm password do not match': 'A nova senha e a senha de confirmação não correspondem',
          'Current password is not valid': 'A senha atual não está correta',
          'New password is not secure enough': 'A nova senha não é segura',
          'New password can not be equal to current password': 'A nova senha não pode ser igual à senha atual',
          'Password should have at least 6 characters and contains numbers, uppercase and lowercase letters combinations or special characters': 'A senha deve ter pelo menos 6 caracteres e conter números, combinações de letras maiúsculas e minúsculas ou caracteres especiais',
          'Not secure': 'Não é seguro',
          'New password can not be equal to current access code': 'A nova senha não pode ser igual ao código de acesso',
          'Now just insert the received access code in': 'Agora é só digitar o código de acesso recebido em',
          'The specified email is not registered': 'O email especificado não está registrado',
          'Only managers, teachers and students can request fast access codes': 'Apenas executivos, professores e alunos podem solicitar códigos de acesso rápido',
          'Could not generate fast access code': 'Não foi possível gerar o código de acesso rápido',
          'Could not get user identification': 'Não foi possível obter o ID do usuário',
          'Login': 'Iniciar sessão',
          'Portuguese': 'Português',
          'The operation was successful': 'A operação foi bem sucedida',
          'The operation could not be completed. Please, try again': 'A operação não pode ser completada. Por favor, tente novamente',
          'All rights reserved': 'Todos os direitos reservados',
          'Could not send email. Please, verify your internet connection.': 'Não foi possível enviar o e-mail. Por favor, verifique sua conexão à internet.',
          'The school is not active': 'A escola não está ativa',
          'Your account is disabled': 'Sua conta está desativada',
          'Update available. Do you want to reload?': 'Atualização disponível. Você quer atualizar?',
          'It seems you do not have an active internet connection.': 'Parece que você não tem uma conexão ativa com a Internet.',

          'Hello': 'Olá',
          'Change user': 'Mudar usuário',

          'Verified by Google ReCAPTCHA': 'Verificado pelo Google ReCAPTCHA'
     }
}

export default pt;
