import { useGetProject } from "./useGetProject";
import { useProjectData } from "./useProjectData";
import { useDOMTitle } from "./useDOMTitle";
import { useLayoutScript } from "./useLayoutScript";
import { useLoadLogo } from "./useLoadLogo";
import { useFavicon } from './useFavicon';
import { useAnimationScripts } from './useAnimationScripts';

export const useLoadProject = () => {
  const { project } = useGetProject();
  const { data } = useProjectData({ project });
  const { logo } = data;

  useLoadLogo({ project, logo });
  useFavicon({project});
  useDOMTitle({ project });
  useAnimationScripts({project, data});
  useLayoutScript();
  
};
