import { useEffect } from "react";
import HtmlService from "../../../Services/HtmlService";

export const useAnimationScripts = ({ project, data }) => {
  useEffect(() => {
    if (project && data) {
      data.styles.forEach((style) => {
        HtmlService.addLayoutCss("/projects/" + project + "/" + style);
      });

      data.scripts.forEach((script) => {
        HtmlService.addLayoutScript("/projects/" + project + "/" + script);
      });
    }
  }, [data, project]);
};
