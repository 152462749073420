import React, { useEffect, useRef } from 'react';
import { useGetProject } from '../../utils/hooks';
import { animation } from './animation';

export const BorderAnimation = ({children}) => {
  const containerRef = useRef(null);
  const {project} = useGetProject()

  useEffect(() => {
    if(project === 'nival') {
      const container = document.getElementById('root') || document.getElementById('dt-root')
      animation(container, containerRef.current)
    }
  },[project])

  return (
      <div ref={containerRef} className='border-animation-card' >
        <div className="glows"/>
        {children}
      </div>
  )
}