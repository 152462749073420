import {Component} from 'react'
import {NotificationManager} from 'react-notifications'

import RoutingService from "./RoutingService";
import DataService from "./DataService";

class MessageService extends Component {
    static showSuccessNotification(message) {
        NotificationManager.success(message + '.', DataService.capitalize(RoutingService.getProject()), 1500);
    }

    static showInfoNotification(message) {
        NotificationManager.success(message + '.', DataService.capitalize(RoutingService.getProject()), 3000);
    }    

    static showWarningNotification(message) {
        NotificationManager.warning(message + '.', DataService.capitalize(RoutingService.getProject()), 3000);
    }
}

export default MessageService;
