import React from "react";
import { useTranslation } from "react-i18next";
import './styles.css'

const errors = {
  500: {
    title: "System error",
    text: "Sorry, it seems we have an unexpected error",
  },
  404: {
    title: "Not found resource",
    text: "Sorry, it seems the requested resource does not exists or is temporally off",
  },
  403: {
    title: "Access denied",
    text: "Sorry, it seems your user account does not have access to the requested resource",
  },
};

const ErrorScreen = ({ error }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="error_content">
        <h1 className='error_title'>
          <span className="error_code">{error}</span>
          <br />
          {t(errors[error].title)}
        </h1>
        <p className='error_text'>{t(errors[error].text)}</p>
      </div>
    </React.Fragment>
  );
};

export default ErrorScreen;
