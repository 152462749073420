let data = {
    logo: 'logo.jpeg',
    logo_anim: 'logo.jpeg',
    brand: {
        en: 'KILKENNY BI. All rights reserved.'
    },
    slogan: {
        en: 'AI-powered <br class="d-block d-lg-none"/><span class="bolder">KILKENNY DESIGN<br class="d-block"/> strategy</span></span> solution',
    },
    styles: [
        'styles.css'
    ],
    scripts: [
        'script.js'
    ]
};

export default data;
