import React from 'react';
import MyReactComponent from "../MyReactComponent";
import './App.css';
import './AppResponsive.css';
import Layout from './Layout'

import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

import TagManager from 'react-gtm-module';

import { projects_idGa } from '../parameters';

if(projects_idGa) {
  TagManager.initialize({ gtmId: projects_idGa });
  console.log('[GA]: Google Analytics connected')
}

const FallbackComponent = () => (
  <div>AppSignal: There was an error...</div>
);

class App extends MyReactComponent {
    state = {
      appsignal: new Appsignal({ 
        key: this.props.app_signal_monitoring_key
      }),
    }

    render() {
      const {allowed_locales, version, token_payload_url, google_recaptcha_site_key, font, font_bold} = this.props;

      return (
        <ErrorBoundary 
          instance={this.state.appsignal}
          tags={{ tag: "traces_web_authentication" }}
          fallback={(error) => <FallbackComponent />}>

          <Layout 
            allowed_locales={allowed_locales} 
            version={version} 
            token_payload_url={token_payload_url} 
            google_recaptcha_site_key={google_recaptcha_site_key}
            font={font}
            font_bold={font_bold}
          />

        </ErrorBoundary>        
      )
    }
}

export default App;
