import { useEffect } from "react";
import HtmlService from "../../../Services/HtmlService";

export const useLayoutScript = () => {
  useEffect(() => {
    const id = setTimeout(
      () => HtmlService.addLayoutScript("/assets/js/script_new.js"),
      1300
    );
    return () => clearTimeout(id);
  }, []);
};
