import MyReactComponent from "../MyReactComponent";

class HtmlService extends MyReactComponent {
    static addLayoutScript(src) {
        let script = document.createElement("script");
        script.src = src;
        script.async = false;

        document.body.appendChild(script);
    }

    static addLayoutCss(url) {
        let head = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;

        head.appendChild(link);
    }

    static showLoadingComponent(show_logo = false) {
        document.getElementById('loader').style.display = 'block';
        if (show_logo) document.getElementById('loader-logo').style.display = 'block';
    }

    static showContent() {
        document.getElementById('dt-root').style.display = 'block';
    }

    static resetContentScroll() {
        document.getElementById('content-wrapper').scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    static fullScreenMode() {
        let html = document.documentElement;

        if (html.requestFullscreen) html.requestFullscreen();
        else if (html.webkitRequestFullscreen) html.webkitRequestFullscreen();
        else if (html.msRequestFullscreen) html.msRequestFullscreen();
    }

    static exitfullScreenMode() {
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
    }

    static isOnline() {
        return navigator.onLine;
    }

    static autoFocus() {
        let first_input = document.querySelector('input[type="text"]');
        if (!first_input) first_input = document.querySelector('input[type="email"]');

        if (first_input) first_input.focus();
    }
}

export default HtmlService;
