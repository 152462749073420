let data = {
    logo: 'logo.svg',
    logo_anim: 'logo.svg',
    brand: {
        en: 'Nival. All rights reserved.',
        es: 'Nival. Todos los derechos reservados.',
    },
    slogan: {
        en: 'Better <span class="bolder">software<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span></span>faster',
        es: 'Mejor <span class="bolder">software<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span></span>más rápido',
    },
    styles: [
        'styles.css',
        'animation.css',
        'border.css'
    ],
    scripts: [
        'script.js'
    ]
};

export default data;
