import {Component} from 'react'

class FormService extends Component {
    static toJson(id) {
        let data = new FormData(document.getElementById(id));
        let entity = {};
        data.forEach(function (value, key) {
            entity[key] = value;
        });

        return JSON.stringify(entity);
    }

    static disableSubmit(id) {
        let submit = document.getElementById(id);
        if (!submit) return ;

        submit.disabled = true;
        submit.innerHTML += '...';
    }

    static enableSubmit(id) {
        let submit = document.getElementById(id);
        if (!submit) return ;

        submit.disabled = false;
        submit.innerHTML = submit.innerHTML.replace('...', '');
    }

    static clean(id) {
        let form = document.getElementById(id);
        form.reset();
        form.elements[0].focus();
    }

    static getValidationMessage(input, t) {
        if (input.validity.rangeOverflow) return t('Range overflow') + '.';
        if (input.validity.patternMismatch) return t('Pattern mismatch') + '.';
        if (input.validity.rangeUnderflow) return t('Range underflow') + '.';
        if (input.validity.tooLong) return t('Too long') + '.';
        if (input.validity.typeMismatch) return t('Type mismatch') + '.';
        if (input.validity.valueMissing) return t('Required') + '.';
    }

    static validateData(id, t) {
        let form = document.getElementById(id);
        let inputs = form.elements;

        for (let i=0; i<inputs.length; i++) {
            inputs[i].addEventListener('input', (x) => {
                let label = document.querySelector('label[for="' + inputs[i].id + '"]');
                let error = label.getElementsByTagName('span')[0];

                if (inputs[i].checkValidity()) {
                    error.textContent = "";
                    error.style.display = 'none';
                }
                else {
                    error.textContent = " " + FormService.getValidationMessage(inputs[i], t);
                    error.style.display = 'inline';
                }
            });
        }
    }

    static isValid(id, t) {
        let form = document.getElementById(id);
        let inputs = form.elements;
        let is_valid = true;

        for (let i=0; i<inputs.length; i++) {
            let label = document.querySelector('label[for="' + inputs[i].id + '"]');
            if (!label) continue;

            let error = label.getElementsByTagName('span')[0];
            error.textContent = "";
            error.style.display = 'inline';

            if (inputs[i].checkValidity() === true) continue;
            error.textContent = " " + FormService.getValidationMessage(inputs[i], t);
            is_valid = false;
        }

        return is_valid;
    }

    static showValidationMessages(input_id, message) {
        if (!message) return;

        let label = document.querySelector('label[for="' + input_id + '"]');
        let error = label.getElementsByTagName('span')[0];
        error.textContent = " " + message;
        error.style.display = 'inline';        
    }
}

export default FormService;
