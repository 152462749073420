window.env = {
  'GENERIC_BACKEND_RAILS_ACCOUNT':'https://backend-account-p-dvcptst3aa-uw.a.run.app',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_PROJECTS':'CUSTOMS',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_LOCALES_PROJECTS': 'en,es',  
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_VERSION_PROJECTS': 'https://backend-customs-api-p-dvcptst3aa-uw.a.run.app',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_FONTS_PROJECTS':'Poppins',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_FONTS_BOLD_PROJECTS':'PoppinsBold',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_RECAPTCHA_SITE_KEY_PROJECTS': '',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_APPSIGNAL_MONITORING_KEY_PROJECTS': '',
  'GENERIC_FRONTEND_REACTJS_AUTHENTICATION_GOOGLE_ANALYTICS': '',
  'NODE_ENV': 'production'
};
