let es = {
    translation: {
        'Has already been taken': 'Ya ha sido utilizado',
        'Range overflow': 'Por encima de lo permitido',
        'Pattern mismatch': 'Patrón incorrecto',
        'Range underflow': 'Por debajo de lo permitido',
        'Too long': 'Demasiado largo',
        'Type mismatch': 'Tipo de dato no válido',
        'Required': 'Requerido',

        'Username or email': 'Nombre de usuario o email',
        'Password or Access code': 'Contraseña o Código de acceso',
        'Remember': 'Recordar',
        'Log In': 'Entrar',
        'English': 'Inglés',
        'Spanish': 'Español',
        'Invalid credential': 'Credenciales no válidas',
        'Account': 'Cuenta',
        'Profile': 'Perfil',
        'Logout': 'Cerrar sesión',
        'Receive quick access password by email': 'Recibir clave de acceso rápido por email',
        'Resend quick access password by email': 'Reenviar clave de acceso rápido por email',
        'Enter email': 'Especifique un email',
        'Send password': 'Enviar clave',
        'Unable to send email': 'No se pudo enviar el email',
        'Verify the email account for getting your new quick access password': 'Verifique la cuenta de email para obtener su nueva clave de acceso rápido',
        'Cancel': 'Cancelar',
        'You are already Login': 'Usted ya está autenticado',
        'Loading account info': 'Cargando información de su cuenta',
        'Your access code has expired': 'Su código de acceso ha expirado',
        'Set a secure password': 'Establecer una contraseña segura',
        'Your access code is not secure enough': 'Su código de acceso no es seguro',
        'Please, set a secure password': 'Por favor, establezca una contraseña segura',
        'Current Access code': 'Código de acceso actual',
        'New password': 'Contraseña nueva',
        'Confirm password': 'Confirmar contraseña',
        'Set': 'Establecer',
        'Password and confirm password do not match': 'La nueva contraseña y la contraseña de confirmación no coindicen',
        'Current password is not valid': 'La contraseña actual no es correcta',
        'New password is not secure enough': 'La nueva contraseña no es segura',
        'New password can not be equal to current password': 'La nueva contraseña no puede ser igual a la contraseña actual',
        'Password should have at least 6 characters and contains numbers, uppercase and lowercase letters combinations or special characters': 'La contraseña debe tener al menos 6 caracteres y contener números, combinaciones de letras mayúsculas y minúsculas o caracteres especiales',
        'Not secure': 'No es segura',
        'New password can not be equal to current access code': 'La nueva contraseña no puede ser igual al código de acceso',
        'Now just insert the received access code in': 'Ahora solo ingrese el código de acceso recibido en',
        'The specified email is not registered': 'El email especificado no está registrado',
        'Only managers, teachers and students can request fast access codes': 'Solo ejecutivos, profesores y estudiantes pueden solicitar códigos de acceso rápido',
        'Could not generate fast access code': 'No se pudo generar el código de acceso rápido',
        'Could not get user identification': 'No se pudo obtener la identificación del usuario',
        'Login': 'Iniciar sesión',
        'Portuguese': 'Portugués',
        'The operation was successful': 'La operación fue satisfactoria',
        'The operation could not be completed. Please, try again': 'No se pudo completar la operación. Por favor, inténtelo nuevamente',
        'All rights reserved': 'Todos los derechos reservados',
        'Could not send email. Please, verify your internet connection.': 'No se pudo enviar el email. Por favor, verifique su conexión a internet.',
        'The school is not active': 'El colegio no está activo',
        'Your account is disabled': 'Su cuenta está deshabilitada',
        'Update available. Do you want to reload?': 'Actualización disponible. ¿Desea actualizar?',
        'It seems you do not have an active internet connection.': 'Parece que no tienes una conexión a Internet activa.',

        'Hello': 'Hola',
        'Change user': 'Cambiar usuario',
        
        'Verified by Google ReCAPTCHA': 'Verificado por Google ReCAPTCHA'
    }
}

export default es;
