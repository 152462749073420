export const generic_backend_authentication = window.env.GENERIC_BACKEND_RAILS_ACCOUNT;

export const projects_google_recaptcha_site_keys = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_RECAPTCHA_SITE_KEY_PROJECTS;
export const projects_app_signal_monitoring_keys = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_APPSIGNAL_MONITORING_KEY_PROJECTS || null;

export const projects_names = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_PROJECTS;
export const projects_locales = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_LOCALES_PROJECTS;
export const projects_versions = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_VERSION_PROJECTS;
export const projects_token_payload_urls = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_TOKEN_PAYLOAD_URL_PROJECTS || null;
export const projects_fonts = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_FONTS_PROJECTS;
export const projects_fonts_bold = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_FONTS_BOLD_PROJECTS;

export const generic_frontend_reactjs_authentication = 'plain_encrypt';
export const projects_idGa = window.env.GENERIC_FRONTEND_REACTJS_AUTHENTICATION_GOOGLE_ANALYTICS;