import { useEffect, useRef } from "react";

export const useInputFocus = () => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus()
  },[])

  return { inputRef };
}