import React from "react";
import { useInputFocus } from "../utils/hooks";

export const AccessCode = ({
  handleAccessChange,
  handleKeyDown,
  handlePaste,
  access_code,
}) => {
  const { inputRef } = useInputFocus();

  return (
    <div className="acces-code">
      {[0, 1, 2, 3, 4, 5].map((num) => {
        return (
          <input
            type="text"
            ref={num === 0 ? inputRef : undefined}
            onChange={(e) => {
              handleAccessChange(num, e);
            }}
            onKeyDown={(e) => handleKeyDown(e, num)}
            onPaste={(e) => handlePaste(num, e)}
            value={access_code[num]}
            maxLength={1}
            key={num}
          />
        );
      })}
    </div>
  );
};
