import {Component} from 'react'
import {projects_names} from "../parameters.js";

class RoutingService extends Component {
    static redirectHome() {
        window.location.assign('/');
    }

    static redirectTo(path) {
        window.location.assign(path);
    }

    static isModule(module) {
        return (window.location.pathname.indexOf(module) === 0);
    }

    static urlContainsExactly(path) {
        return (
            window.location.pathname === (path) ||
            window.location.pathname === (path + '/')
        );
    }

    static urlContains(path) {
        return (
            window.location.pathname.indexOf(path) >= 0
        );
    }

    static setPath(path) {
        window.history.pushState(null, null, path);
    }

    static getParamValue(name, default_value = null) {
        let url = new URL(window.location.href);
        let value = url.searchParams.get(name);
        if (!value) return default_value;
        return value;
    }

    static isUrl(value) {
        return value.indexOf('http') === 0;
    }

    static getProject() {
        if(this.isUrl) {
            return 'smarter'
        }
        return (this.getParamValue('project') || projects_names.split('|')[0]).toLowerCase();
    }
}

export default RoutingService;
