let data = {
    logo: 'logo.svg',
    logo_anim: 'logo.svg',
    brand: {
        en: 'Integrated Automated Systems Assistant. All rights reserved.',
        es: 'Integrated Automated Systems Assistant. Todos los derechos reservados.',
    },
    slogan: {
        en: 'AI-powered <span class="bolder">Integrated<br class="d-none d-md-block"/> Automated Systems <br class="d-none d-md-block"/>assistant</span> tool',
        es: '<span class="bolder">Asistente de aduanas</span><br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>impulsado por IA'
    },
    styles: [
        'styles.css'
    ],
    scripts: []
};

export default data;
