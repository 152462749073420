import React from "react";
import { withTranslation } from "react-i18next";
import { NotificationContainer } from "react-notifications";
import * as PasswordStrength from "@fnando/password_strength";
import { Offline } from "react-detect-offline";
// import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import {AccessCode} from './components/AccessCode'
import { EnvFeedback } from './components/EnvFeedback';
import MyReactComponent from "../MyReactComponent";
import FormService from "../Services/FormService";
import DataService from "../Services/DataService";
import RoutingService from "../Services/RoutingService";
import CookieService from "../Services/CookieService";

import ApiService from "../Services/ApiService";
import HtmlService from "../Services/HtmlService";

import withCustomProject from './utils/HOC/withCustomProject';

import { projects_names, projects_idGa } from "../parameters.js";

import "react-notifications/lib/notifications.css";
import { BorderAnimation } from './components/BorderAnimation/BorderAnimation';
// const recaptchaRef = React.createRef();

class LoginLayout extends MyReactComponent {
  tryRequire(path) {
    try {
      return require(`${path}`);
    } catch (err) {
      return null;
    }
  }

  state = {
    project: this.getProject(),
    locale: RoutingService.getParamValue("locale", null),
    token: RoutingService.getParamValue("token"),
    is_login:
      RoutingService.getParamValue("token") != null &&
      RoutingService.getParamValue("token") !== "null",
    user_has_expiration_date:
      RoutingService.getParamValue("user_has_expiration_date") === "1",
    data: this.getData(),
    show_login: true,
    show_assign_access_code: false,
    loading_expiration_data: false,
    password_has_expired: false,
    set_secure_password: false,
    access_code: ["", "", "", "", "", ""],
    // project_logo: (this.tryRequire('./projects/' + this.getProject() + '/logo_anim.gif')) ? 'logo_anim.gif' : 'logo.svg',
    current_year: new Date().getFullYear(),
    version: "...",
    is_online: HtmlService.isOnline(),
    isEco: RoutingService.getParamValue('eco') === '1',

    styles: {
      background_color:
        "#" +
        RoutingService.getParamValue("background_color", "282e40") +
        " !important",
      primary_color:
        "#" +
        RoutingService.getParamValue("primary_color", "38cfcb") +
        " !important",
      primary_color_dark:
        "#" +
        RoutingService.getParamValue("primary_color_dark", "2cb5b1") +
        " !important",
      form_color:
        "#" +
        RoutingService.getParamValue("form_color", "3e4353d9") +
        " !important",
      secondary_color:
        "#" +
        RoutingService.getParamValue("secondary_color", "a9abb3") +
        " !important",
      secondary_color_dark:
        "#" +
        RoutingService.getParamValue("secondary_color_dark", "93969e") +
        " !important",
      text_color:
        "#" +
        RoutingService.getParamValue("text_color", "ffffff") +
        " !important",
      menu_background:
        "#" +
        RoutingService.getParamValue("menu_background", "ffffffd9") +
        " !important",
      menu_text_color:
        "#" +
        RoutingService.getParamValue("menu_text_color", "282e40") +
        " !important",
      footer_text_color:
        "#" +
        RoutingService.getParamValue("footer_text_color", "545454") +
        " !important",
      offline_bg_color:
        "#" +
        RoutingService.getParamValue("offline_bg_color", "545968") +
        " !important",
    },

    password_visible: false,
    grettings_username: CookieService.getCookie("grettings_username"),
    grettings_name: CookieService.getCookie("grettings_name"),

    recaptchaToken: null,
    refreshReCaptcha: false
  };

  constructor() {
    super();

    // throw new Error('Testing AppSignal on React from Web Authentication...');

    this.onLogin = this.onLogin.bind(this);
    this.onRememberPassword = this.onRememberPassword.bind(this);
    this.onChangeLocale = this.onChangeLocale.bind(this);
    this.onShowRememberPassword = this.onShowRememberPassword.bind(this);
    this.onShowLogin = this.onShowLogin.bind(this);
    this.onSetSecurePassword = this.onSetSecurePassword.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
    // this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    this.passwordToggle = this.passwordToggle.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.handleAccessChange = this.handleAccessChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.setRecaptchaTokenFunc = this.setRecaptchaTokenFunc.bind(this);
  }

  componentDidMount() {
    if (projects_idGa) {
      console.log("[GA] The GA id exist");
      window.dataLayer.push({ event: "page_view" });
    }
    setTimeout(() => {
      window.parent.postMessage({ action: "loaded" }, "*");
    }, 1000);

    if (RoutingService.isUrl(this.props.version)) {
      ApiService.requestUrl(
        this.props.t,
        this.props.version,
        (data) => {
          this.setState({
            version: data.version,
          });

          this.loadExpirationData();
        },
        (code, error) => {
          this.setState({
            version: "",
          });
        },
        this.state.locale
      );
    } else {
      this.setState({
        version: this.props.version,
      });
    }

    if (!this.state.locale) {
      this.setState({
        locale: this.props.allowed_locales.split(",")[0],
      });
    }
  }

  setRecaptchaTokenFunc(recaptchaToken) {
    this.setState({recaptchaToken: recaptchaToken});
  };

  passwordToggle() {
    this.setState({ password_visible: !this.state.password_visible });
  }

  getProject() {
    return (
      RoutingService.getParamValue("project") || projects_names.split("|")[0]
    ).toLowerCase();
  }

  getData() {
    const projectName = this.getProject();

    const data =
      require(`./projects/${projectName.toLowerCase()}/data.js`).default;

    return data;
  }

  loadExpirationData() {
    if (this.state.is_login && this.state.user_has_expiration_date) {
      this.setState({
        loading_expiration_data: true,
        set_secure_password: true,
      });

      ApiService.requestEndpoint(
        this.props.t,
        "users/password_check",
        this.state.token,
        (data) => {
          data = data[0];

          this.setState(
            {
              loading_expiration_data: false,
              email: data.email,
              expiration_date: data.expiration_date,
              password_has_expired: data.password_has_expired,
            },
            () => {
              HtmlService.autoFocus();
            }
          );
        },
        (code, error) => {},
        this.state.locale
      );
    }
  }

  onPasswordInput(event, t) {
    event.preventDefault();
    let password = document.getElementById("new_password").value;
    let label = document.querySelector("label[for='new_password']");
    let error = label.getElementsByTagName("span")[0];

    if (password.length === 0) {
      error.textContent = "";
      error.style.display = "none";
      return;
    }

    let strength = new PasswordStrength.test(this.state.user_email, password);
    if (strength.isValid("good")) {
      error.textContent = "";
      error.style.display = "none";
    } else {
      error.textContent = t("Not secure");
      error.style.display = "inline";
    }
  }

  formatDate(date) {
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    let year = date.getFullYear();

    return day + "/" + month + "/" + year;
  }

  formatTime(date) {
    let tt = "am";

    let hours = date.getHours();
    if (hours >= 12) {
      hours -= 12;
      tt = "pm";
    }
    if (hours < 10) hours = "0" + hours;

    let minutes = date.getMinutes();
    if (minutes < 10) minutes = "0" + minutes;

    return hours + ":" + minutes + " " + tt;
  }

  login(data) {
    ApiService.postEndpoint(
      this.props.t,
      "users/auth",
      this.state.token,
      data,
      (response) => {
        let user = response[0].user;
        let photo_url = response[0].photo_url;

        let roles_names_en = [];
        let roles_names_es = [];
        let roles_names_pt = [];

        response[0].roles.forEach((x_role) => {
          roles_names_en.push(x_role.name_en);
          roles_names_es.push(x_role.name);
          roles_names_pt.push(x_role.name_pt);
        });

        roles_names_en = roles_names_en.join(", ");
        roles_names_es = roles_names_es.join(", ");
        roles_names_pt = roles_names_pt.join(", ");

        let extra_data = data.extra_data;

        let expiration_date = response[0].expiration_date;
        let expiration_time = null;
        let valid_seconds = response[0].valid_seconds;

        // The expiration date in server is checked out,
        // But it is used to manage the client date due to the timezone in client could be different from timezone in server...
        if (expiration_date) {
          let now = new Date();
          now.setSeconds(now.getSeconds() + valid_seconds);
          expiration_date = this.formatDate(now);
          expiration_time = this.formatTime(now);
        }

        CookieService.setCookie("grettings_username", data.username);
        CookieService.setCookie("grettings_name", user.name);

        let message = {
          action: "login",
          data: {
            token: response[0].token,
            token_valid_minutes: response[0].token_valid_minutes,
            client: "",
            id: user.id,
            name: user.name,
            email: user.email,
            photo_url: photo_url,
            expiration_date: expiration_date,
            expiration_time: expiration_time,
            roles_en: roles_names_en,
            roles_es: roles_names_es,
            roles_pt: roles_names_pt,
            allowed_tools: response[0].allowed_tools,
            extra_data: extra_data,
            remember: data.remember === "on",
          },
        };

        window.parent.postMessage(message, "*");
        FormService.enableSubmit("submit");
        this.setState({
          access_code: ['', '', '', '', '', '']
        })
      },
      (code, error) => {
        FormService.enableSubmit("submit");

        if (Array.isArray(error)) error = error[0].error;

        if (error === "Your access code has expired")
          this.setState({ error: error });
        else if (error === "Your account is disabled")
          this.setState({ error: error });
        else if (code === 403) this.setState({ error: "Invalid credential" });
      },
      this.state.locale,
      this.state.project == null
    );
  }

  onLogin(event) {
    event.preventDefault();

    FormService.disableSubmit("submit");
    let data = JSON.parse(FormService.toJson("form"));

    if (this.state.access_code.filter(code => code !== '').length > 0) {
      data['password'] = this.state.access_code.join('');
    }

    if (this.props.token_payload_url) {
      // Getting extra data for token authentication...
      ApiService.postUrl(
        this.props.t,
        this.props.token_payload_url,
        { email: data.username },
        (response) => {
          data.extra_data = response;
          this.login(data);
        },
        (code, error) => {
          FormService.enableSubmit("submit");

          let error_text = "Could not get user identification";
          if (Array.isArray(error)) error = error[0];
          if (error["error"]) error_text = error["error"];

          this.setState({ error: error_text });
        },
        this.state.locale,
        false
      );
    } else {
      this.login(data);
    }
  }

  changeUser(event) {
    event.preventDefault();

    CookieService.removeCookie("grettings_username");
    CookieService.removeCookie("grettings_name");

    this.setState({
      grettings_username: null,
      grettings_name: null,
    });
  }

  onLogout() {
    let message = {
      action: "logout",
    };

    window.parent.postMessage(message, "*");
  }

  onCancel() {
    let message = {
      action: "cancel",
    };

    window.parent.postMessage(message, "*");
  }

  onRememberPassword() {
    if (!FormService.isValid("form", this.props.t)) return;

    FormService.disableSubmit("submit");
    let data = JSON.parse(FormService.toJson("form"));

    ApiService.postEndpoint(
      this.props.t,
      "users/assign_access_code",
      this.state.token,
      data,
      (response) => {
        response = response[0];

        if (response["email"] && response["email"] === data["email"]) {
          this.onShowLogin();

          this.setState({
            success_text:
              "Verify the email account for getting your new quick access password",
            success_email_after_assign_code: data.email,
          });
        }
      },
      (code, error) => {
        FormService.enableSubmit("submit");

        if (!error[0]) {
          this.setState({
            error:
              "Could not send email. Please, verify your internet connection.",
          });
          return;
        }

        error = error[0].error;
        if (error.includes("Communication service error")) error = "Could not send email. Please, verify your internet connection.";
        this.setState({ error: error, refreshReCaptcha: true });
      },
      this.state.locale,
      true
    );
  }

  onSetSecurePassword(event) {
    event.preventDefault();
    if (!FormService.isValid("form", this.props.t)) return;

    let new_password = document.getElementById("new_password").value;
    let confirm_password = document.getElementById("confirm_password").value;
    let error = false;

    if (new_password !== confirm_password) {
      FormService.showValidationMessages(
        "new_password",
        this.props.t("Password and confirm password do not match") + "."
      );
      error = true;
    }

    if (error) return;

    FormService.disableSubmit("submit");
    let data = JSON.parse(FormService.toJson("form"));
    ApiService.postEndpoint(
      this.props.t,
      "users/change_password",
      this.state.token,
      data,
      (response) => {
        let message = {
          action: "change_password",
        };

        window.parent.postMessage(message, "*");
      },
      (code, error) => {
        FormService.enableSubmit("submit");
        error = error[0].error;

        if (
          error === "New password can not be equal to current access code" ||
          error === "New password is not secure enough"
        ) {
          FormService.showValidationMessages(
            "new_password",
            this.props.t(error) + "."
          );
        }
      },
      this.state.locale,
      true
    );
  }

  onChangeLocale(event, locale) {
    event.preventDefault();

    const { i18n } = this.props;
    i18n.changeLanguage(locale);
    this.setState({ locale: locale });

    let message = {
      action: "change_locale",
      data: {
        locale: locale,
      },
    };

    window.parent.postMessage(message, "*");
  }

  onShowRememberPassword(event) {
    event.preventDefault();

    this.setState(
      {
        error: null,
        success_text: null,
        success_email_after_assign_code: null,
        show_login: false,
        show_assign_access_code: true,
        recaptcha_site_key: this.props.google_recaptcha_site_key,
        recaptchaToken: null,
        refreshReCaptcha: false
      },
      () => {
        HtmlService.autoFocus();
      }
    );

    FormService.validateData("form", this.props.t);
  }

  handleAccessChange(index, e) {
    e.preventDefault();
    const input = e.target;
    let nextStatus = [...this.state.access_code];
    if(input.value.match(/[0-9]/g) || input.value === '') {
      nextStatus[index] = input.value;
      this.setState({
        access_code: nextStatus,
      });
      input.value !== '' && index < nextStatus.length - 1 && input.nextElementSibling.focus();
    }
  }

  handleKeyDown(e, num) {
    const input = e.target;
    const { key } = e;
    if(key === 'ArrowRight') {
      e.preventDefault();
      num < this.state.access_code.length -1 && input.nextElementSibling.focus();
    }
    if(key === 'ArrowLeft') {
      e.preventDefault();
      num > 0 && input.previousElementSibling.focus();
    }
  }

  handlePaste(num, e) {
    const pastedText = e.clipboardData.getData('text');
    const { length } = pastedText
    if(length === 1) {
      e.target.value = pastedText;
      this.handleAccessChange(num, e);
      return 
    }
    if(length >= 6 && length > 0) {
      const splited = pastedText.replace(/[^0-9]/g, '').split('');
      this.setState({
        access_code: splited,
      });
      return
    }
    
  }

  onShowLogin() {
    this.setState(
      {
        error: null,
        success_text: null,
        success_email_after_assign_code: null,
        show_login: true,
        show_assign_access_code: false,
        access_code: ['', '', '', '', '', '']
      },
      () => {
        HtmlService.autoFocus();
      }
    );
  }

  // onChangeCaptcha(value) {
  //   if (value) {
  //     this.onRememberPassword();
  //   }

  //   recaptchaRef.current.reset();
  // }

  globalStyles() {
    return `
            :root {
              --background_color: ${this.state.styles.background_color};
              --primary_color: ${this.state.styles.primary_color};
              --primary_color_dark: ${this.state.styles.primary_color_dark};
              --form_color: ${this.state.styles.form_color};
              --secondary_color: ${this.state.styles.secondary_color};
              --secondary_color_dark: ${this.state.styles.secondary_color_dark};
              --text_color: ${this.state.styles.text_color};
              --menu_background: ${this.state.styles.menu_background};
              --menu_text_color: ${this.state.styles.menu_text_color};
              --footer_text_color: ${this.state.styles.footer_text_color};
              --font: ${DataService.capitalize(
                this.props.font
              )}, sans-serif !important;
              --font-bold: ${DataService.capitalize(
                this.props.font_bold
              )}, sans-serif !important;
              --offline_bg_color: ${this.state.styles.offline_bg_color};
            }
        `;
  }

  render() {
    const { t, allowed_locales } = this.props;

    const nodeEnv = RoutingService.getParamValue('env', 'development');
    
    let error_text = <p className="text-danger">{t(this.state.error)}</p>;
    let success_text = (
      <p className="text-success">{t(this.state.success_text)}</p>
    );
    return (
      <React.Fragment>
        <style>{this.globalStyles()}</style>
        <Offline
          polling={false}
          onChange={(online) => {
            this.setState({ is_online: online });
          }}
        />
        <div
          id="offline"
          className={this.state.is_online ? "online" : "offline"}
        >
          {!this.state.is_online && t("Offline")}
        </div>

        <header
          className={"dt-header " + (this.state.is_online ? "" : "offline")}
        >
          {
            nodeEnv !== "production" &&
            <EnvFeedback nodeEnv={DataService.capitalize(nodeEnv)} />
          }

          {
            allowed_locales.split(",").length > 1 &&
            <div className="dt-header__container">
              <div className="dt-header__toolbar">
                <div className="dt-nav-wrapper">
                  <ul className="dt-nav">
                    <li className="dt-nav__item dropdown">
                      <a
                        href="#app"
                        className="dt-nav__link dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.locale === "en" && <span>English</span>}
                        {this.state.locale === "es" && <span>Español</span>}
                        {this.state.locale === "pt" && <span>Português</span>}
                      </a>

                      <div className="dropdown-menu dropdown-menu-right">
                        {allowed_locales.split(",").map((x_locale) => {
                          return (
                            <a
                              key={x_locale}
                              className="dropdown-item"
                              href="#app"
                              onClick={(event) =>
                                this.onChangeLocale(event, x_locale)
                              }
                            >
                              {x_locale === "en" && (
                                <React.Fragment>
                                  <i className="flag-icon flag-icon-gb mr-2" />
                                  <span>{t("English")}</span>
                                </React.Fragment>
                              )}

                              {x_locale === "es" && (
                                <React.Fragment>
                                  <i className="flag-icon flag-icon-es mr-2" />
                                  <span>{t("Spanish")}</span>
                                </React.Fragment>
                              )}

                              {x_locale === "pt" && (
                                <React.Fragment>
                                  <i className="flag-icon flag-icon-pt mr-2" />
                                  <span>{t("Portuguese")}</span>
                                </React.Fragment>
                              )}
                            </a>
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </header>

        <div className="dt-login--container dt-app-login--container">
          <div id="background"></div>
          <div id="version">{this.state.version}</div>
          {this.state.isEco && (
            <span className="material-symbols-outlined eco">
              temp_preferences_eco
            </span>
          )}
          {
            <div id="login-container">
              <div id="login-container-inner">
                <img
                  id="logo_anim_secondary"
                  className="dt-brand__logo-img logo_anim"
                  src={
                    "/projects/" +
                    this.state.project.toLowerCase() +
                    "/" +
                    this.state.data.logo_anim
                  }
                  alt="Traces"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.onCancel()}
                />

                <div className="login-description">
                  <div>
                    <div className="tick" style={{ marginBottom: "25px" }}>
                      &nbsp;
                    </div>
                    <p
                      className="text-title palid"
                      dangerouslySetInnerHTML={{
                        __html: this.state.data.slogan[this.state.locale],
                      }}
                    />
                    <p className="text-description palid">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      <br />
                      sed do eiusmod tempor incididunt ut labore et.
                    </p>
                  </div>
                </div>

                <div className="login-form-container">
                  <img
                    id="logo_anim_main"
                    className="dt-brand__logo-img logo_anim"
                    src={
                      "/projects/" +
                      this.state.project.toLowerCase() +
                      "/" +
                      this.state.data.logo_anim
                    }
                    alt="Traces"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onShowLogin()}
                  />
                  <BorderAnimation>
                  <div className="dt-login__content">
                    <div className="dt-login__content-inner">
                      {this.state.set_secure_password && (
                        <React.Fragment>
                          <h1 className="text-success">
                            <i
                              style={{ marginRight: "7px", fontSize: "125%" }}
                              className="icon icon-check"
                            ></i>
                            {t("You are already Login")}
                          </h1>

                          {this.state.loading_expiration_data && (
                            <p>{t("Loading account info")}...</p>
                          )}

                          {!this.state.loading_expiration_data && (
                            <p className="mb-1">{this.state.user_email}</p>
                          )}

                          {!this.state.loading_expiration_data &&
                            this.state.password_has_expired && (
                              <p className="text-danger">
                                {t("Your access code has expired")}.
                              </p>
                            )}

                          {!this.state.loading_expiration_data &&
                            !this.state.password_has_expired &&
                            this.state.expiration_date && (
                              <p className="text-warning">
                                {t("Your access code is not secure enough")}.{" "}
                                {t("Please, set a secure password")}.
                              </p>
                            )}

                          <h3
                            className="text-info"
                            style={{ marginTop: "10px" }}
                          >
                            {t("Set a secure password")}
                          </h3>

                          <form
                            id="form"
                            onSubmit={(event) =>
                              this.onSetSecurePassword(event)
                            }
                            noValidate
                          >
                            {this.state.error && error_text}

                            <label htmlFor="new_password">
                              <span className="error" />
                            </label>

                            <div
                              className="input-group"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="inputGroupPrepend1"
                                >
                                  <i className="icon icon-forgot-password icon-fw mr-2 mr-sm-1" />
                                </span>
                              </div>

                              <input
                                required
                                type="password"
                                className="form-control"
                                id="new_password"
                                name="new_password"
                                onInput={(event) => {
                                  this.onPasswordInput(event, t);
                                }}
                                placeholder={t("New password")}
                                aria-describedby="inputGroupPrepend1"
                              />
                            </div>

                            <small className="form-text">
                              {t(
                                "Password should have at least 6 characters and contains numbers, uppercase and lowercase letters combinations or special characters"
                              )}
                              .
                            </small>

                            <div
                              className="input-group"
                              style={{
                                marginTop: "15px",
                                marginBottom: "20px",
                              }}
                            >
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="inputGroupPrepend2"
                                >
                                  <i className="icon icon-forgot-password icon-fw mr-2 mr-sm-1" />
                                </span>
                              </div>

                              <input
                                required
                                type="password"
                                className="form-control"
                                id="confirm_password"
                                name="confirm_password"
                                placeholder={t("Confirm password")}
                                aria-describedby="inputGroupPrepend2"
                              />
                            </div>

                            <div
                              className="form-group"
                              style={{ marginBottom: "0" }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <button
                                  id="submit"
                                  type="submit"
                                  className="btn btn-primary-traces"
                                >
                                  {t("Set")}
                                </button>

                                {!this.state.loading_expiration_data &&
                                  !this.state.password_has_expired && (
                                    <button
                                      type="button"
                                      className="btn btn-light btn-secondary-traces"
                                      style={{ marginLeft: "10px" }}
                                      onClick={() => this.onCancel()}
                                    >
                                      {t("Cancel")}
                                    </button>
                                  )}

                                {!this.state.loading_expiration_data &&
                                  this.state.password_has_expired && (
                                    <button
                                      type="button"
                                      className="btn btn-light btn-secondary-traces"
                                      style={{ marginLeft: "7px" }}
                                      onClick={() => this.onLogout()}
                                    >
                                      {t("Logout")}
                                    </button>
                                  )}
                              </div>
                            </div>
                          </form>
                        </React.Fragment>
                      )}

                      {!this.state.set_secure_password &&
                        this.state.is_login && (
                          <React.Fragment>
                            <h1
                              className="text-success"
                              style={{ marginTop: "15px" }}
                            >
                              <i
                                style={{ marginRight: "7px", fontSize: "125%" }}
                                className="icon icon-check"
                              ></i>
                              {t("You are already Login")}
                            </h1>

                            <p className="mb-1">{this.state.user_email}</p>

                            <div
                              className="form-group"
                              style={{
                                marginBottom: "15px",
                                marginTop: "25px",
                              }}
                            >
                              <button
                                style={{ width: "100%" }}
                                type="button"
                                className="btn btn-primary-traces"
                                onClick={() => this.onLogout()}
                              >
                                {t("Logout")}
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                      {!this.state.set_secure_password &&
                        !this.state.is_login &&
                        this.state.show_login && (
                          <form
                            id="form"
                            onSubmit={(event) => this.onLogin(event)}
                          >
                            {<h1 className="login-title">{t("Login")}</h1>}

                            {this.state.error && error_text}
                            {this.state.success_text && success_text}

                            {this.state.grettings_name && (
                              <h3 className="greetings">
                                {t("Hello")} {this.state.grettings_name}
                                <a
                                  href="#app"
                                  onClick={(event) => this.changeUser(event)}
                                >
                                  {t("Change user")}
                                </a>
                              </h3>
                            )}

                            {this.state.grettings_username && (
                              <input
                                type="hidden"
                                name="username"
                                value={this.state.grettings_username}
                              />
                            )}

                            {this.state.success_email_after_assign_code && (
                              <div
                                className="form-group"
                                style={{
                                  marginBottom: "7px",
                                  flexDirection: "column",
                                }}
                              >
                                <label htmlFor="password">
                                  {t(
                                    "Now just insert the received access code in"
                                  )}
                                  :{" "}
                                  <b>
                                    {this.state.success_email_after_assign_code}
                                  </b>
                                </label>
                                <input
                                  type="hidden"
                                  name="username"
                                  value={
                                    this.state.success_email_after_assign_code
                                  }
                                />
                              </div>
                            )}

                            {!this.state.success_email_after_assign_code &&
                              !this.state.grettings_username && (
                                <div
                                  className="input-group"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="inputGroupPrepend1"
                                    >
                                      <i className="icon icon-user-o icon-fw mr-2 mr-sm-1" />
                                    </span>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control"
                                    name="username"
                                    defaultValue={
                                      this.state.success_email_after_assign_code
                                    }
                                    placeholder={t("Username or email")}
                                    aria-describedby="inputGroupPrepend1"
                                  />
                                </div>
                              )}
                            {!this.state.success_email_after_assign_code ? (
                              <div className="input-group">
                                <div className="input-group-prepend password">
                                  <span
                                    className="input-group-text"
                                    id="inputGroupPrepend2"
                                  >
                                    <i className="icon icon-forgot-password icon-fw mr-2 mr-sm-1" />
                                  </span>
                                </div>

                                <input
                                  type={
                                    this.state.password_visible
                                      ? "text"
                                      : "password"
                                  }
                                  className="form-control"
                                  name="password"
                                  defaultValue=""
                                  placeholder={t("Password or Access code")}
                                  aria-describedby="inputGroupPrepend2"
                                />

                                <div
                                  className="input-group-prepend password-toggle"
                                  onClick={() => this.passwordToggle()}
                                >
                                  <span
                                    className="input-group-text"
                                    id="inputGroupPrepend2"
                                  >
                                    {!this.state.password_visible && (
                                      <i className="fa fa-eye fa-fw mr-2 mr-sm-1" />
                                    )}

                                    {this.state.password_visible && (
                                      <i className="fa fa-eye-slash fa-fw mr-2 mr-sm-1" />
                                    )}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <AccessCode
                                access_code={this.state.access_code}
                                handleAccessChange={this.handleAccessChange}
                                handlePaste={this.handlePaste}
                                handleKeyDown={this.handleKeyDown}
                              />
                            )}

                            {!this.state.success_email_after_assign_code && (
                              <div
                                className="custom-control custom-checkbox"
                                style={{
                                  paddingLeft: "14px",
                                  marginTop: "25px",
                                }}
                              >
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id="checkbox-1"
                                  name="remember"
                                  defaultChecked={true}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="checkbox-1"
                                >
                                  {t("Remember")}
                                </label>
                              </div>
                            )}

                            <div
                              className="form-group"
                              style={{
                                marginBottom: "0",
                                marginTop: "35px",
                                textAlign: "center",
                              }}
                            >
                              <button
                                id="submit"
                                type="submit"
                                className="btn btn-primary btn-primary-traces text-uppercase btn-submit-login"
                                style={{ width: "70%" }}
                              >
                                {t("Log In")}
                              </button>
                            </div>

                            <div
                              className="form-group"
                              style={{
                                marginBottom: "0",
                                marginTop: "15px",
                                textAlign: "center",
                              }}
                            >
                              <button
                                className="btn btn-light btn-secondary-traces btn-secondary-login"
                                onClick={(event) =>
                                  this.onShowRememberPassword(event)
                                }
                                style={{ width: "70%" }}
                              >
                                {t(
                                  `${
                                    !this.state.success_email_after_assign_code
                                      ? "Receive"
                                      : "Resend"
                                  } quick access password by email`
                                )}
                              </button>
                            </div>

                            <div
                              className="form-group"
                              style={{
                                marginBottom: "0",
                                marginTop: "25px",
                                textAlign: "center",
                              }}
                            >
                              <label id="mobile-keyboard-footer">
                                Copyright &copy; {this.state.current_year}{" "}
                                {this.state.data.brand[this.state.locale]}
                              </label>
                            </div>
                          </form>
                        )}

                      {this.state.show_assign_access_code && (
                        <form
                          id="form"
                          onSubmit={(event) => {
                            event.preventDefault();
                            this.onRememberPassword();
                            // recaptchaRef.current.execute();
                          }}
                          noValidate
                        >
                          {this.state.error && error_text}

                          <label htmlFor="email">
                            {t("Email")}: *<span className="error" />
                          </label>

                          <div
                            className="input-group"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                <i className="icon icon-email icon-fw mr-2 mr-sm-1" />
                              </span>
                            </div>

                            <input
                              required
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder={t("Enter email")}
                              aria-describedby="inputGroupPrepend3"
                            />
                          </div>

                          {/*
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            theme="dark"
                            hl={this.state.locale}
                            sitekey={this.state.recaptcha_site_key}
                            onChange={this.onChangeCaptcha}
                          />
                        */}

                          {
                            this.state.recaptcha_site_key &&
                            <GoogleReCaptchaProvider reCaptchaKey={this.state.recaptcha_site_key}>
                              <GoogleReCaptcha
                                className="google-recaptcha-v3-class"
                                onVerify={this.setRecaptchaTokenFunc}
                                refreshReCaptcha={this.state.refreshReCaptcha}
                                language={this.state.locale}
                              />
                            </GoogleReCaptchaProvider>
                          }

                          <div className="form-group" style={{ marginBottom: "0" }}>
                            <div
                              style={{
                                display: "inline-block",
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              <button
                                id="submit"
                                type="submit"
                                className="btn btn-primary-traces"
                              >
                                {t("Send password")}
                              </button>

                              <button
                                type="button"
                                className="btn btn-light btn-secondary-traces"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  // recaptchaRef.current.reset();
                                  this.onShowLogin();
                                }}
                              >
                                {t("Cancel")}
                              </button>
                            </div>
                          </div>

                          {
                            this.state.recaptchaToken &&
                            <React.Fragment>
                              <h1 className="text-success" style={{fontSize: '12px', margin: 0, marginTop: '15px'}}>
                                <i className="icon icon-check" style={{ float: 'left', marginTop: '1px', marginRight: "7px", fontSize: "150%" }}></i>
                                {t("Verified by Google ReCAPTCHA")}
                              </h1>
                            </React.Fragment>
                          }
                        </form>
                      )}
                    </div>
                  </div>
                  </BorderAnimation>
                  
                </div>
              </div>
            </div>
          }

          <NotificationContainer />
        </div>

        <footer>
          Copyright &copy; {this.state.current_year}{" "}
          {this.state.data.brand[this.state.locale]}
        </footer>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withCustomProject(LoginLayout));
