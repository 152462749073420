import { useEffect } from "react";

export const useLoadLogo = ({ project, logo }) => {
  useEffect(() => {
    if (project && logo) {
      console.log("/projects/" + project.toLowerCase() + "/" + logo)
      document.getElementById("loader-logo").src =
        "/projects/" + project.toLowerCase() + "/" + logo;
    }
  }, [project, logo]);
};
