import {Component} from 'react'

class MyReactComponent extends Component {
    componentDidCatch(error, errorInfo) {
        console.log(error);
        return;
    }
}

export default MyReactComponent;
