import {Component} from 'react'
import axios from 'axios';
import MessageService from "./MessageService"
import HtmlService from "./HtmlService"
import RoutingService from "./RoutingService"
import {generic_backend_authentication} from "../parameters.js";
import {projects_names} from "../parameters.js";

class ApiService extends Component {
    static showSuccessNotification(t) {
        MessageService.showSuccessNotification(
            t('The operation was successful')
        );
    }

    static getProject() {
        return (RoutingService.getParamValue('project') || projects_names.split('|')[0]).toLowerCase();
    }

    static showWarningNotification(t) {
        if (HtmlService.isOnline()) {
            MessageService.showWarningNotification(
                t('The operation could not be completed. Please, try again')
            );
        }
        else {
            MessageService.showWarningNotification(
                t('It seems you do not have an active internet connection')
            );
        }
    }

    static requestEndpoint(t, endpoint, token, onSuccess, onError, locale, service_url=generic_backend_authentication) {
        axios.get(service_url + '/' + endpoint, {
            headers: {
                'token': token,
                'project': ApiService.getProject()
            }
        })
        .then(response => {
            onSuccess(response.data);
        })
        .catch((error) => {
            if (error.response) onError(error.response.status, error.response.data);
            else onError(error.status, error.message);
            ApiService.showWarningNotification(t);
        });
    }

    static requestUrl(t, url, onSuccess, onError, locale) {
        axios.get(url)
        .then(response => {
            onSuccess(response.data);
        })
        .catch((error) => {
            if (error.response) onError(error.response.status, error.response.data);
            else onError(error.status, error.message);
        });
    }    

    static postEndpoint(t, endpoint, token, data, onSuccess, onError, locale, show_notification=true, service_url=generic_backend_authentication) {
        axios.post(service_url + '/' + endpoint, data, {
            headers: {
                'token': token,
                'project': ApiService.getProject()
            }
        })
        .then(response => {
            onSuccess(response.data);
            if (show_notification) ApiService.showSuccessNotification(t);
        })
        .catch((error) => {
            if (error.response) onError(error.response.status, error.response.data);
            else onError(error.status, error.message);
            ApiService.showWarningNotification(t);
        });
    }

    static postUrl(t, url, data, onSuccess, onError, locale, show_notification=true) {
        axios.post(url, data)
        .then(response => {
            onSuccess(response.data);
            if (show_notification) ApiService.showSuccessNotification(t);
        })
        .catch((error) => {
            if (error.response) onError(error.response.status, error.response.data);
            else onError(error.status, error.message);
            ApiService.showWarningNotification(t);
        });
    }
}

export default ApiService;
