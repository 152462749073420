import { useEffect } from "react";

export const useDOMTitle = ({ project }) => {
  useEffect(() => {
    if (project) {
      document.title = document.title =
        project.toUpperCase() + " Login";
    }
  }, [project]);
};
