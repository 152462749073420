let data = {
    logo: 'logo.svg',
    logo_anim: 'logo_anim.gif',
    brand: {
        en: 'TRACES AI. All rights reserved.',
        es: 'TRACES AI. Todos los derechos reservados.',
        pt: 'TRACES AI. Todos os direitos reservados.',
    },
    slogan: {
        en: 'Predictive <span class="bolder">Analysis</span><br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>in education<span class="d-inline d-md-none">&nbsp;</span><br class="d-none d-md-block"/>with <span class="bolder">AI</span>',
        es: '<span class="bolder">Análisis</span> predictivo<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>en educación<span class="d-inline d-md-none">&nbsp;</span><br class="d-none d-md-block"/>con <span class="bolder">IA</span>',
        pt: '<span class="bolder">Análise</span> preditivo<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>na educação<span class="d-inline d-md-none">&nbsp;</span><br class="d-none d-md-block"/>com <span class="bolder">IA</span>',
    },
    styles: [
        'styles.css'
    ],
    scripts: [
        'three.91.min.js',
        'waves.js'
    ]
};

export default data;
