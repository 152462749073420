import RoutingService from "../../../Services/RoutingService";
import { projects_names } from "../../../parameters";

export const useGetProject = () => {
  const project = (
    RoutingService.getParamValue("project") || projects_names.split("|")[0]
  ).toLowerCase();

  return { project };
};
