import { useEffect } from "react";

export const useFavicon = ({ project }) => {
  useEffect(() => {
    if (project) {
      const link =
        document.querySelector("link[rel~='icon']") ||
        document.createElement("link");
      link.rel = "icon";
      link.href = "/projects/" + project.toLowerCase() + "/favicon.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }, [project]);
};
