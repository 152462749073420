let data = {
    logo: 'logo.svg',
    logo_anim: 'logo.svg',
    brand: {
        en: 'Smarter. All rights reserved.',
        es: 'Smarter. Todos los derechos reservados.',
        pt: 'Smarter. Todos os direitos reservados.',
    },
    slogan: {
        en: 'AI-powered <span class="bolder"><span class="bolder">sales<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>strategy</span></span> solution',
        es: 'Solución de<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span><span class="bolder"><span class="bolder">estrategia de ventas</span></span><br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>impulsada por IA',
        pt: 'Solução de<br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span><span class="bolder"><span class="bolder">estratégia de vendas</span></span><br class="d-none d-md-block"/><span class="d-inline d-md-none">&nbsp;</span>com tecnologia de IA',
    },
    styles: [
        'styles.css'
    ],
    scripts: [
        'script.js'
    ]
};

export default data;
